import React from "react"

import styled from "styled-components"
import media from "styles/media"
import colors from "styles/colors"
import text from "styles/text"

const SysBanner = () => {
  return (
    <Wrapper>
      <BannerText>
      "We're moving....domains! Check it out!"
      </BannerText>
      <LearnMore href="https://vasion.com/printerlogic-is-now-vasion-print/">
        Learn More <strong>→</strong>
      </LearnMore>
    </Wrapper>
  )
}

const LearnMore = styled.a`
  ${text.bodySmBold};
  color: ${colors.white};

  strong {
    ${text.bodySmBold};
    color: ${colors.primaryOrange};
  }

  ${media.mobile} {
    ${text.bodySmBold};

    strong {
      ${text.bodySmBold};
    }
  }
`
const BannerText = styled.p`
  ${text.bodySm};
  color: ${colors.white};

  strong {
    ${text.bodySmBold};
    color: ${colors.primaryOrange};
  }

  ${media.mobile} {
    ${text.bodySm};
    font-size: 2.804vw !important;

    strong {
      ${text.bodySmBold};
    }
  }
`
const Wrapper = styled.div`
  background-color: ${colors.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2.222vw;
  height: 2.778vw;

  ${media.fullWidth} {
    gap: 32px;
    height: 40px;
  }

  ${media.tablet} {
    gap: 3.125vw;
    height: 3.906vw;
  }

  ${media.mobile} {
    text-align: center;
    flex-wrap: wrap;
    gap: unset;
    height: auto;
    padding: 1.869vw;
  }
`
export default SysBanner
